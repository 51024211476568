import * as Sentry from '@sentry/vue'
// based on: https://gearboxgo.com/articles/web-application-development/setting-up-sentry-with-nuxt-3

export default defineNuxtPlugin({
  name: 'sentry',
  parallel: true,
  async setup(nuxtApp) {
    const vueApp = nuxtApp.vueApp
    const config = useRuntimeConfig()

    Sentry.init({
      app: vueApp,
      dsn: config.public.SENTRY_DSN_PUBLIC ?? null,
      environment: config.public.env,
      integrations: [Sentry.browserTracingIntegration({ router: useRouter() })],
      // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring. We recommend adjusting this value in production.
      tracesSampleRate: config.public.SENTRY_TRACES_SAMPLE_RATE,
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      // tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
      ignoreErrors: [
        'Connection to Indexed Database server lost',
        'client is offline',
        'reCAPTCHA has already been rendered in this element',
        'Failed to load script: https://www.googletagmanager.com/gtm.js',
        "Failed to execute 'getValue' on 'CookieDeprecationLabel'",
        'NetworkError when attempting to fetch resource',
        'Failed to fetch dynamically imported module',
        'Non-Error promise rejection captured with value: Timeout',
        'auth/network-request-failed',
        'AbortError: The operation was aborted',
        'Missing or insufficient permissions'
      ]
    })
  }
})
