<template>
  <NuxtPage />
</template>

<script setup>
useHead({
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk} | Network Rack Planner | PATCHBOX` : 'Free online 19" Rack Planning Tool | Network Rack Planner | PATCHBOX';
  }
})
</script>